<template>
  <div>
    <iframe width="100%" height="1000px"
      :src="url"
      frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  setup() {
    return { url: process.env.VUE_APP_SUBSCRIPTION_STATISTICS_URL }
  },
}
</script>
